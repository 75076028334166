import "core-js/modules/es.array.push.js";
import InputField from './InputField.vue';
import Loading from './loading.vue';
export default {
  components: {
    InputField,
    Loading
  },
  data() {
    return {
      sjh: '',
      email: '',
      timesg: false,
      errmsge: false,
      errmsgf: false,
      showdownFail: false,
      isshowToast: true,
      toasting: false,
      toasted: false,
      showcontBottom: false,
      sjhstyle: 'frame systemfont van_kpborder',
      emailstyle: 'frame systemfont van_kpborder',
      sjhph: this.$t('IsSucPage.inputphone'),
      sjhphi: this.$t('IsSucPage.inputphonei'),
      emailph: this.$t('IsSucPage.inputemail'),
      emailphi: this.$t('IsSucPage.inputemaili'),
      showMobile: false,
      showEmail: false,
      switch: 0,
      // 0手机 1邮箱
      titlecss: 'lefywid3 innertext floatleft systemfont',
      contcss: 'innertext floatright systemfont',
      tempwidth: document.documentElement.clientWidth + 'px',
      hrwidth: document.documentElement.clientWidth - 30 + 'px',
      toastwidth: document.documentElement.clientWidth - 54 + 'px',
      inputwidth: document.documentElement.clientWidth - 66 + 'px',
      closeyes: 'closebtn btnyes systemfont',
      closecancel: 'closebtn btncancel systemfont',
      InvoiceAmount: '0.00',
      InvoiceDatetime: '',
      InvoiceReId: '',
      InvoiceType: '',
      titlelan: '',
      remaintimes: '',
      // 发票重发剩余次数
      circlebtn: 'circlebtno',
      btmpic: '',
      timer: null,
      changeIndexsjh: 0,
      changeIndexemail: 0
    };
  },
  mounted() {
    if (this.$store.state.invoiceRequest.TotalAmountWithTax) {
      this.InvoiceAmount = Number(this.$store.state.invoiceRequest.TotalAmountWithTax).toFixed(2);
    }
    if (this.$store.state.invoiceRequest.InvRequestTime) {
      this.InvoiceDatetime = this.$store.state.invoiceRequest.InvRequestTime.replace('T', ' ');
    }
    this.InvoiceType = this.$store.state.invoiceRequest.InvKind;
    this.$store.commit('setYxStatus', true);
    this.$store.commit('setSjhStatus', true);
    this.titlelan = this.$store.state.lang;
    // if (localStorage.getItem('weixin') == 'open') {
    //     this.circlebtn = 'circlebtn'
    // } else {
    //     this.circlebtn = 'circlebtno'
    // }
    this.getbtmpic();
  },
  methods: {
    clearBlank() {
      this.email = this.email.replace(/\s+/g, '');
    },
    focusedsjh() {
      this.changeIndexsjh = 1;
      document.getElementById("sjhid").setAttribute("placeholder", "");
      if (!this.sjh) {
        document.getElementById("sjhid").setAttribute("placeholder", this.sjhph);
      }
      this.sjhstyle = 'frame van_kpbordering systemfont';
      this.errmsge = false;
      this.timesg = false;
    },
    unfocusedsjh() {
      document.getElementById("sjhid").setAttribute("placeholder", "");
      if (this.sjh) {
        this.changeIndexsjh = 1;
      } else {
        this.changeIndexsjh = 0;
      }
      var numReg = /^[0-9]+$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(this.sjh) && this.sjh.length == 11) {
        this.sjhstyle = 'frame van_kpborder systemfont';
        this.errmsge = false;
        this.timesg = false;
      } else {
        if (this.sjh) {
          this.sjhstyle = 'frame van_kpbordererr systemfont';
          this.errmsge = true;
          this.timesg = false;
        } else {
          this.sjhstyle = 'frame van_kpborder systemfont';
          this.errmsge = false;
          this.timesg = false;
        }
      }
    },
    focusedemail() {
      this.changeIndexemail = 1;
      document.getElementById("emailid").setAttribute("placeholder", "");
      if (!this.email) {
        document.getElementById("emailid").setAttribute("placeholder", this.emailph);
      }
      this.emailstyle = 'frame van_kpbordering systemfont';
      this.errmsgf = false;
      this.timesg = false;
    },
    unfocusedemail() {
      document.getElementById("emailid").setAttribute("placeholder", "");
      if (this.email) {
        this.changeIndexemail = 1;
      } else {
        this.changeIndexemail = 0;
      }
      var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      var numRe = new RegExp(numReg);
      if (numRe.test(this.email)) {
        this.emailstyle = 'frame van_kpborder systemfont';
        this.errmsgf = false;
        this.timesg = false;
      } else {
        if (this.email) {
          this.emailstyle = 'frame van_kpbordererr systemfont';
          this.errmsgf = true;
          this.timesg = false;
        } else {
          this.emailstyle = 'frame van_kpborder systemfont';
          this.errmsgf = false;
          this.timesg = false;
        }
      }
    },
    async getRemainTimes() {
      await this.axios.post('cdapply/GetRemainTimes/', {
        "invoiceRequestId": this.$store.state.invoiceRequest.Id
      }).then(response => {
        this.remaintimes = response.data.data;
        return response.data.data;
      });
    },
    async onOpenMobile() {
      await this.getRemainTimes();
      this.showMobile = true;
      this.switch = 0;
      this.timesg = false;
    },
    async onOpenEmail() {
      await this.getRemainTimes();
      this.showEmail = true;
      this.switch = 1;
      this.timesg = false;
    },
    sjhexamsub(val) {
      var numReg = /^[0-9]+$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(val) || val.length != 11) {
        this.sjhstyle = 'frame systemfont van_kpbordererr';
        this.errmsge = true;
        this.$store.commit('setSjhStatus', false);
        return false;
      }
      return true;
    },
    emailexamsub(val) {
      var numReg = "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])";
      var numRe = new RegExp(numReg);
      if (!numRe.test(val)) {
        this.emailstyle = 'frame systemfont van_kpbordererr';
        this.errmsgf = true;
        this.$store.commit('setYxStatus', false);
        return false;
      }
      return true;
    },
    async onClickConfirmPhone() {
      if (this.sjhexamsub(this.sjh)) {
        await this.getRemainTimes();
        if (this.remaintimes <= 0) {
          this.timesg = true;
          return false;
        }
        this.showMobile = false;
        this.toasting = true;
        this.toasted = false;
        await this.axios.post('cdapply/InvoiceTicketResend/', {
          "Id": this.$store.state.invoiceRequest.Id,
          "MobilePhone": this.sjh
        }).then(response => {
          var resdata = response.data;
          if (resdata.code == 0) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.getRemainTimes();
              this.toasting = false;
              this.toasted = true;
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.toasting = false;
                this.toasted = false;
              }, 3000);
            }, 3000);
          } else if (resdata.code == 3201) {
            // console.log(resdata)
          }
        });
      }
    },
    async onClickConfirmEmail() {
      if (this.emailexamsub(this.email)) {
        await this.getRemainTimes();
        if (this.remaintimes <= 0) {
          this.timesg = true;
          return false;
        }
        this.showEmail = false;
        this.toasting = true;
        this.toasted = false;
        await this.axios.post('cdapply/InvoiceTicketResend/', {
          "Id": this.$store.state.invoiceRequest.Id,
          "Emails": this.email
        }).then(response => {
          var resdata = response.data;
          if (resdata.code == 0) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.getRemainTimes();
              this.toasting = false;
              this.toasted = true;
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.toasting = false;
                this.toasted = false;
              }, 3000);
            }, 3000);
          } else {
            this.toasting = false;
            this.toasted = true;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.toasting = false;
              this.toasted = false;
            }, 3000);
          }
        });
      }
    },
    onClickClosePhone() {
      this.showMobile = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.sjhstyle = 'frame systemfont van_kpborder';
        this.errmsge = false;
      }, 500);
    },
    onClickCloseEmail() {
      this.showEmail = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.emailstyle = 'frame systemfont van_kpborder';
        this.errmsgf = false;
      }, 500);
    },
    // 查看发票
    onClickView() {
      this.axios.post('cdapply/GetBackAddress/').then(response => {
        this.$store.commit('setBackAddress', response.data.data);
      });
      this.$router.push('showFapiao');
    },
    onClickCard() {
      this.showdownFail = true;
    },
    getbtmpic() {
      this.axios.post('cdapply/GetBottomPicture/').then(response => {
        if (response.data.data) {
          this.btmpic = response.data.data;
        }
      });
    }
  }
};